const Home = () => import('@theme-views/public/pages/Home')
const Login = () => import('@theme-views/public/pages/Login')
const Faq = () => import('@theme-views/public/pages/Faq')
const Support = () => import('@theme-views/public/pages/Support')
const NotFound = () => import('@theme-views/public/pages/NotFound')
const CustomPage = () => import('@theme-views/public/pages/CustomPage')

const ExclusivePromotion = () => import('@theme-views/public/pages/ExclusivePromotion')
const LogoutPromotion = () => import('@theme-views/public/pages/LogoutPromotion')
const DeleteAccountPromotion = () => import('@theme-views/public/pages/DeleteAccountPromotion')
const BlockedRegister = () => import('@theme-views/public/pages/BlockedRegister')
const FailedOauth = () => import('@theme-views/public/pages/FailedOauth')

const UserMobileHome = () => import('@theme-views/user/mobile/Home')

const FakeUserHome = () => import('@theme-views/user/pages/Home/FakeHome')
const UserHome = () => import('@theme-views/user/pages/Home')
const UserProfile = () => import('@theme-views/user/pages/Profile')
const UserOtherProfile = () => import('@theme-views/user/pages/OtherProfile')
const UserMessages = () => import('@theme-views/user/pages/Messages')
const UserMessagesBin = () => import('@theme-views/user/pages/MessagesBin')
const UserMessagesChat = () => import('@theme-views/user/pages/MessagesChat')
const UserVisits = () => import('@theme-views/user/pages/Visits')
const UserFavorites = () => import('@theme-views/user/pages/Favorites')
const UserOtherFavorites = () => import('@theme-views/user/pages/OtherFavorites')
const UserSearchProfiles = () => import('@theme-views/user/pages/SearchProfiles')
const UserSearchResults = () => import('@theme-views/user/pages/SearchResults')
const UserSettings = () => import('@theme-views/user/pages/Settings')
const UserDisableNotifications = () => import('@theme-views/user/pages/DisableNotifications')
const StopNotifications = () => import('@theme-views/public/pages/StopNotifications')
const UserSubscriptionCredits = () => import('@theme-views/user/pages/SubscriptionCredits')
const UserPremium = () => import('@theme-views/user/pages/Premium')

import Vue from 'vue'
const $t = Vue.i18n.translate

let routes = [
	{
		name: 'home',
		component: Home
	},
	{
		name: 'login',
		component: Login
	},
	{
		name: 'faq',
		component: Faq,
	},
	{
		name: 'contact',
		component: Support, //should've been for contact component
	},
	{
		name: 'support',
		component: Support,
	},
	{
		name: 'user-home',
		component: UserHome
	},
	{
		name: 'user-mobile-home',
		component: UserMobileHome
	},
	{
		name: 'user-activate-profile',
		component: FakeUserHome,
		props: {
			showActivation: true
		}
	},
    {
        name: 'complete-register',
        component: FakeUserHome,
        props: {
            showCompletedRegister: true
        }
    },
	{
		name: 'user-profile',
		component: UserProfile
	},
	{
		name: 'user-other-profile',
		component: UserOtherProfile
	},
	{
		name: 'user-photos',
		redirect: {
			name: 'user-profile',
			params: {
				open: 'photos'
			}
		},
	},
	{
		name: 'user-messages',
		component: UserMessages
	},
	{
		name: 'user-messages-bin',
		component: UserMessagesBin
	},
	{
		name: 'user-messages-chat',
		component: UserMessagesChat
	},
	{
		name: 'user-visits',
		component: UserVisits
	},
	{
		name: 'user-favorites',
		component: UserFavorites
	},
	{
		name: 'user-other-favorites',
		component: UserOtherFavorites
	},
	{
		name: 'user-search-profiles',
		component: UserSearchProfiles
	},
	{
		name: 'user-search-results',
		component: UserSearchResults
	},
	{
		name: 'user-settings',
		component: UserSettings
	},
	{
		name: 'user-credits',
		component: UserSubscriptionCredits,
	},
	{
		name: 'user-premium',
		component: UserPremium
	},
	{
		name: 'exclusive-promotion',
		component: ExclusivePromotion
	},
	{
		name: 'logged-out',
		component: LogoutPromotion
	},
	{
		name: 'user-disable-notifications',
		component: UserDisableNotifications
	},
    {
        name: 'user-stop-notifications',
        component: StopNotifications
    },
	{
		name: 'account-deleted',
		component: DeleteAccountPromotion
	},
	{
		name: 'blocked-register',
		component: BlockedRegister
	},
	{
		name: 'failed-oauth',
		component: FailedOauth
	},
	{
		path: '*',
		component: CustomPage,
		props: (route) => ({ page_name: route.path.substring(1) })
    },
]

export function templateCustomBeforeEach() {
	window.scrollTo(0, 0);
}

export default {
	routes
}
